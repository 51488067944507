import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import CheckedIcon from './checked-icon.svg'
import { showCheckedIcon, media, IconClose } from 'ui'

const Radio = ({
  name,
  label,
  value,
  isSelected,
  isCorrect,
  isWrong,
  disabled,
  prefix,
  onChange,
  ...props
}) => {
  const [haveImage, setHaveImage] = useState(false)
  const [marked, setMarked] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleHaveImage = (value) => {
    setHaveImage(value.toString().includes('<img'))
  }

  useEffect(() => {
    if (label) {
      handleHaveImage(label)
    }
  }, [label])

  return (
    <RadioWrapper
      isWrong={isWrong}
      isCorrect={isCorrect}
      onMouseLeave={() => setVisible(!visible)}
      onMouseOver={() => setVisible(true)}
    >
      <>
        <RadioLabel disabled={disabled}>
          {prefix && (
            <RadioPrefix>
              {prefix})
            </RadioPrefix>
          )}
          <RadioInput
            type='radio'
            name={name}
            value={value}
            disabled={disabled}
            checked={isSelected}
            onChange={onChange}
            {...props}
          />
          <RadioLabelText isMarked={marked}>
            {label}
          </RadioLabelText>
          {
            haveImage && <MarkingLine isVisible={marked} />
          }
        </RadioLabel>
      </>
      {
        visible && !disabled && (
          <MarkingButton onClick={() => setMarked(!marked)}>
            <IconClose size={24} />
          </MarkingButton>
        )
      }
    </RadioWrapper>

  )
}

const RadioList = ({ items, name }) => {
  const [optionChecked, setChecked] = useState('')

  return (
    <RadioListWrapper>
      {items.map(item => (
        <Radio
          key={item.value}
          name={name}
          label={item.label}
          value={item.value}
          isSelected={item.isSelected || optionChecked === item.value}
          isCorrect={item.isCorrect}
          disabled={item.disabled}
          prefix={item.value}
          onChange={() => setChecked(item.value)}
        />
      ))}
    </RadioListWrapper>
  )
}

Radio.propTypes = {
  name: t.string.isRequired,
  label: t.node.isRequired,
  value: t.string,
  isSelected: t.bool,
  isCorrect: t.bool,
  prefix: t.string,
  disabled: t.bool,
  onChange: t.func,
  isWrong: t.bool,
}

RadioList.propTypes = {
  items: t.arrayOf(t.shape({
    value: t.string.isRequired,
    label: t.string.isRequired,
    isSelected: t.bool,
    isCorrect: t.bool,
  })).isRequired,
  name: t.string.isRequired,
}

const getStateColor = (theme, isCorrect) => isCorrect ? theme.colours.success : theme.colours.error

export const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  margin: .4em 0;
  padding: 2rem 0;
  align-items: center;
  justify-content: space-between;

  ${({ isCorrect, isWrong }) => (isWrong || isCorrect) && css`
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -2rem;
      right: 0;
      bottom: 0;
      background: ${({ theme }) => rgba(getStateColor(theme, isCorrect), 0.2)};
      border-radius: ${({ theme }) => theme.radius.small};

      ${media.lessThan('phone')`
        left: -1rem;
      `}
    }
  `}
`

const RadioPrefix = styled.span`
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
  padding-right: 1em;
  font-weight: bold;
  font-size: 1.4rem;
`

const RadioLabelText = styled.span`
  position: relative;
  display: inline-block;
  align-items: center;
  font-size: 1.4rem;
  padding-left: 3.6rem;
  text-decoration: ${({ isMarked }) => isMarked ? 'line-through red' : 'none'};
  -webkit-text-decoration: ${({ isMarked }) => isMarked ? 'line-through red' : 'none'};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #bbb;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    transition: background .2s ease;
  }
`

const RadioInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked + ${RadioLabelText} {
    &:before {
      background: url(${CheckedIcon}) no-repeat center ${({ theme }) => theme.colours.primary};
      background-size: 10px auto;
      border-color: ${({ theme }) => theme.colours.primary};
      animation: ${showCheckedIcon} .3s ease forwards;
    }
  }
`

const RadioLabel = styled.label`
  position: relative;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.middle};
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props) => props.disabled ? '.5' : '1'};
  align-items: center;
  margin: 5px;
`

const RadioListWrapper = styled.div`
`

export const MarkingButton = styled.button`
  display: flex;
  background-color: ${({ theme }) => theme.colours.gray2};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: ease-in 200ms;
  margin-right: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colours.error};
    transform: rotate(90deg);
  }
`

export const MarkingLine = styled.div`

  @keyframes progress-animation{
    0% { width: 0%; }
    100% { width: 100%}
  }

  display: ${({ isVisible }) => isVisible ? 'flex' : 'none'};
  background-color: red;
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 999;
  animation: progress-animation 0.2s;
  margin-left: 60px;
`

export { Radio, RadioList }
