import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { useWindowSize } from '@react-hook/window-size/throttled'
import { rgba, darken } from 'polished'
import { AvatarPicture, media } from 'ui'
import { Link } from 'react-router-dom'

import ATTACH_ICON from './attach-icon.svg'
import PDF_ICON from './pdf-icon.svg'
import VIDEO_ICON from './video-icon.svg'
import CHECK_ICON from './check-icon.svg'
import STAR_ICON from './star-icon.svg'
import DELETE_ICON from './delete-icon.svg'
import PIN_ICON from './pin-icon.svg'
import ReactAudioPlayer from 'react-audio-player'

const ThreadItem = ({ title, verified, author, authorPhoto, date, subject, responses, url, pinned, hasVerifiedAnswer, hasMonitorAnswer, page, read }) => {
  const hasAnswers = responses > 0
  localStorage.setItem('backPage', page)
  return (
    <Item read={read} to={{ pathname: url, state: { page: page } }}>
      <Content>
        <TitleWrapper>
          <Title>{title}</Title>
          {pinned && <PinnedTag>Fixado</PinnedTag>}
        </TitleWrapper>
        <Metadata>
          <AvatarPicture
            name={author}
            photo={authorPhoto}
            size='2rem'
            fontSize='.6rem'
          />
          <Author>
            <AuthorName>{author}</AuthorName>
            {verified &&
              <Verified>
                Monitor
              </Verified>}
          </Author>
          <Date>{date}</Date>
          <Responses>
            {Number(responses) === 1 && '1 Resposta'}
            {Number(responses) > 1 && `${responses} Respostas`}
            {!hasAnswers && 'Nenhuma resposta'}
          </Responses>
          {hasVerifiedAnswer && <Dot hasVerifiedAnswer={hasVerifiedAnswer?.isVerified} />}
          {hasMonitorAnswer && <Dot hasMonitorAnswer={hasMonitorAnswer} />}
        </Metadata>
      </Content>
      <Subject>
        {subject}
      </Subject>
    </Item>
  )
}

const ThreadReply = ({
  kind,
  audioFile,
  file,
  author,
  authorPhoto,
  date,
  content,
  verified,
  canChangeVerifiedAnswer,
  verifiedAnswer,
  canDelete,
  canDeleteFunc,
  verifiedAnswerFunc,
}) => {
  const [width] = useWindowSize()
  let extension = 'png'
  if (file) {
    const f = file.split('.')
    extension = f.slice(-1)[0]
  }
  return (
    <ReplyBox verified={verified} verifiedAnswer={verifiedAnswer} canChangeVerifiedAnswer={canChangeVerifiedAnswer}>
      <ReplyMetadata>
        <ReplyPicture>
          <AvatarPicture
            name={author}
            photo={authorPhoto}
            size={width < 580 ? '3rem' : '4rem'}
            fontSize={width < 580 ? '1rem' : '1.4rem'}
          />
        </ReplyPicture>
        <ReplyAuthor>
          <AuthorName>{author}</AuthorName>
          {verified &&
            <Verified hasIcon>
              Monitor
            </Verified>}
          {canChangeVerifiedAnswer
            ? <VerifiedAnswer hasIcon verified={verified} onClick={verifiedAnswerFunc} verifiedAnswer={verifiedAnswer} canChangeVerifiedAnswer={canChangeVerifiedAnswer}>{verifiedAnswer ? 'Verificada' : 'Verificar'}</VerifiedAnswer>
            : <VerifiedAnswer hasIcon verified={verified} verifiedAnswer={verifiedAnswer} canChangeVerifiedAnswer={canChangeVerifiedAnswer}>{verifiedAnswer ? 'Verificada' : 'Verificar'}</VerifiedAnswer>}
        </ReplyAuthor>
        <ReplyDate>{date}</ReplyDate>
      </ReplyMetadata>
      {audioFile ? (
        <>
          <ReactAudioPlayer
            src={`${audioFile}`}
            autoPlay={false}
            controls
          // eslint-disable-next-line react/jsx-closing-bracket-location
          />
        </>
      ) : (
        <ReplyMessage dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {(file || canDelete || audioFile) &&
        <ReplyFooter>
          {file &&
            <FileButton href={file} target='_blank' rel='nofollow noopener noreferrer' extension={extension}>
              Ver anexo
            </FileButton>}
          {canDelete &&
            <DeleteButton onClick={canDeleteFunc}>
              Apagar <span>{kind === 'topic' ? 'tópico' : 'comentário'}</span>
            </DeleteButton>}
        </ReplyFooter>}
    </ReplyBox>
  )
}

ThreadItem.propTypes = {
  title: t.string.isRequired,
  author: t.string.isRequired,
  authorPhoto: t.string,
  date: t.string.isRequired,
  subject: t.string.isRequired,
  responses: t.oneOfType([
    t.string.isRequired,
    t.number.isRequired,
  ]),
  url: t.string.isRequired,
  verified: t.bool,
  pinned: t.bool,
  hasVerifiedAnswer: t.object,
  hasMonitorAnswer: t.bool,
  page: t.number,
  read: t.bool,
}

ThreadReply.propTypes = {
  kind: t.string.isRequired,
  author: t.string.isRequired,
  authorPhoto: t.string,
  date: t.string.isRequired,
  content: t.string.isRequired,
  file: t.string,
  audioFile: t.string,
  verified: t.bool,
  canChangeVerifiedAnswer: t.bool,
  verifiedAnswer: t.oneOfType([
    t.string,
    t.bool,
  ]),
  canDelete: t.bool,
  canDeleteFunc: t.func,
  verifiedAnswerFunc: t.func,
}

const Item = styled(({ read, ...props }) => <Link {...props} />)`
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.medium};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow .2s ease;
  margin-bottom: 2rem;

  ${({ read, theme }) => !read && `
    &:hover {
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    }
  `}

 ${({ read, theme }) => read && `
    background: ${rgba(theme.colours.primary, 0.05)};
    border: 1px solid ${theme.colours.primary};

    & * {
      color: ${theme.colours.primary};
    }

    svg {
      opacity: 1;
    }
  `}
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 10px ${({ theme }) => rgba(theme.colours.secondary, 0.1)};
  }

  ${media.lessThan('phone')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  `}
`

const Content = styled.div`
`

const Subject = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.secondary};
  border: 1px solid ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.secondary};
  padding: .4rem 1rem;
  font-weight: 600;
  border-radius: ${({ theme }) => theme.radius.small};

  ${media.lessThan('phone')`
    order: -1;
    margin-bottom: 1rem;
  `}
`

const Metadata = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`

const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colours.text};
  line-height: 1.4;
`

const PinnedTag = styled.span`
  display: inline-block;
  vertical-align: top;
  color: ${props => darken(0.5, props.theme.colours.warning)};
  background: url(${PIN_ICON}) 8px center no-repeat ${props => props.theme.colours.warning};
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 600;
  padding: .15em .65em .2em 2.3rem;
  margin-left: 1rem;

  ${media.lessThan('phone')`
    margin-left: 0;
    margin-top: .6rem;
  `}
`

const Author = styled.span`
  padding-left: .8rem;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.primary};

  ${media.lessThan('phone')`
    max-width: 6rem;
  `}
`

const Date = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.gray2};

  &:before,
  &:after {
    content: '•';
    padding: 0 .5rem;
  }
`

const Responses = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.primary};
`

const VerifiedAnswer = styled.div`
  cursor: ${props => props.canChangeVerifiedAnswer ? 'pointer' : 'default'};
  display: inline-block;
  vertical-align: top;
  color: #fff;
  background: url(${STAR_ICON}) 8px center no-repeat ${props => props.verifiedAnswer ? props.theme.colours.answer : props.theme.colours.gray3};
  border-radius: 10px;
  font-size: .8em;
  padding: .15em .65em .2em 2.3rem;
  margin-left: 1rem;
  background-size: 11px;

    visibility : ${props => {
    if (props.verifiedAnswer !== 'Topic') {
      if (props.canChangeVerifiedAnswer && !props.verified) {
        return 'visible'
      } else if (!props.canChangeVerifiedAnswer && props.verifiedAnswer && !props.verified) {
        return 'visible'
      } else {
        return 'hidden'
      }
    }
    return 'hidden'
  }};
    margin: 0.3rem 1rem 0.3rem 0.3rem;
    padding: 0.15em 0.65em 0.2em 0.65rem;
    background: ${props => props.verifiedAnswer ? props.theme.colours.answer : props.theme.colours.gray3};
    background-size: 11px;

  ${media.greaterThan('phone')`
    transition: all 400ms ease;
    transform-style: preserve-3d;
    ${({ verifiedAnswer, theme, canChangeVerifiedAnswer }) => verifiedAnswer && canChangeVerifiedAnswer && `
      &:hover{
        transform-origin: center bottom;
        transform: rotateX(-90deg) translateY(100%);
        &:after{
          top: -100%;
          left: 0;
          display: inline-block;
          vertical-align: top;
          border-radius: 10px;
          padding: .15em .65em .2em 2.3rem;
          background: url(${STAR_ICON}) 8px center no-repeat ${theme.colours.answer};
          background-size: 11px;
          box-sizing: border-box;
          content: 'Desmarcar';
          position: absolute;
          transform-origin: left bottom;
          transform: rotateX(90deg);
        }
      }
    `}
  `
  }

  ${media.lessThan('phone')`
    margin-left: 0;
    font-size: .6em;
    visibility : ${props => {
      if (props.verifiedAnswer !== 'Topic') {
        if (props.canChangeVerifiedAnswer) {
          return 'visible'
        } else if (!props.canChangeVerifiedAnswer && props.verifiedAnswer) {
          return 'visible'
        } else {
          return 'hidden'
        }
      }
      return 'hidden'
    }};
    margin: 0.3rem 1rem 0.3rem 0.3rem;
    padding: 0.15em 0.65em 0.2em 0.65rem;
    background: ${props => props.verifiedAnswer ? props.theme.colours.answer : props.theme.colours.gray3};
    background-size: 11px;
  `}
`
const ReplyBox = styled.div`
  position: relative;
  padding: 2rem 2.6rem 3rem 8rem;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-bottom: 2rem;
  ${({ verified, theme, verifiedAnswer }) => verifiedAnswer !== 'Topic' ? verifiedAnswer ? `
    box-shadow: 0 0 0 1px inset ${theme.colours.answer};
  ` : verified && `
    box-shadow: 0 0 0 1px inset ${theme.colours.success};
  ` : 'box-shadow: 0 0 0 0;'}

  ${media.lessThan('phone')`
    padding: 1rem;
  `}

  ${VerifiedAnswer} {
    visibility: ${props => props.canChangeVerifiedAnswer && !props.verified ? 'visible' : null};
  }
`

const ReplyPicture = styled.div`
  position: absolute;
  left: 2rem;
  top: 2rem;

  ${media.lessThan('phone')`
    position: static;
  `}
`

const ReplyMetadata = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colours.gray4};
  margin-bottom: 2rem;
`

const ReplyAuthor = styled.h4`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colours.text};
  padding-bottom: .2rem;
  display: flex;
  align-items: center;

  ${media.lessThan('phone')`
    padding-left: 1rem;
    font-size: 1.4rem;
  `}
`

const ReplyDate = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
  padding-right: 1rem;
  margin-left: auto;

  ${media.lessThan('phone')`
    margin-left: auto;
    text-align: right;
  `}
`

const ReplyMessage = styled.div`
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.5;

  p {
    font-size: 1.4rem;
    line-height: 1.5;
    word-break: break-word;
    color: ${({ theme }) => theme.colours.text};
    margin-bottom: 2rem;
  }

  ul, ol {
    margin: 0 0 2rem;
    padding: 0;

    li {
      font-size: 1.4rem;
    }
  }

  h4, h5, h6 {
    font-size: 1.6rem;
    margin: 2rem 0;
  }

  img {
    max-width: 80%;
    display: inline-block;
    margin-bottom: 2rem;
  }
`

const ReplyFooter = styled.div`
  margin-top: 2rem;
  padding-top: 1.2rem;
  border-top: 1px solid ${({ theme }) => rgba(theme.colours.gray3, 0.2)};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FileButton = styled.a`
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  padding: 1rem 1.3rem 1rem 4.3rem;
  color: ${({ theme }) => theme.colours.primary};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${({ theme }) => rgba(theme.colours.purpleGray, 0.5)};
  font-weight: 600;
  text-decoration: none;
  transition: background .2s ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    left: 1.2rem;
    margin-top: -1rem;
    background: ${({ extension }) => {
    if (extension === 'pdf') {
      return `url(${PDF_ICON}) center no-repeat;`
    } else if (extension === 'mp4') {
      return `url(${VIDEO_ICON}) center no-repeat;`
    }
    return `url(${ATTACH_ICON}) center no-repeat;`
  }}
    background-size: 100% auto;
  }

  &:hover {
    background: ${({ theme }) => rgba(theme.colours.purpleGray, 0.8)};
  }
`

const DeleteButton = styled.span`
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colours.error};
  font-weight: bold;
  transition: opacity .2s ease;
  vertical-align: middle;
  padding-left: 2.4rem;
  cursor: pointer;
  margin-left: auto;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 2rem;
    top: 50%;
    left: 0;
    margin-top: -1rem;
    background: url(${DELETE_ICON}) center no-repeat;
    background-size: 100% 100%;
  }

  &:hover {
    opacity: .85;
  }

  ${media.lessThan('phone')`
    span {
      display: none;
    }
  `}
`

const Verified = styled.span`
  display: inline-block;
  vertical-align: top;
  color: #fff;
  background: url(${CHECK_ICON}) 8px center no-repeat ${props => props.theme.colours.success};
  border-radius: 10px;
  font-size: .8em;
  padding: .15em .65em .2em 2.3rem;

  ${media.lessThan('phone')`
    font-size: .6em;
    margin-left: 0;
    margin: 0.3rem 0.3rem 0.3rem 0;
    background: #00d992;
    padding: 0.2em 0.45em 0.2em 0.45rem;
  `}
`
const Dot = styled.span`
  height: 10px;
  width: 10px;
  ${({ hasVerifiedAnswer, theme, hasMonitorAnswer }) => hasVerifiedAnswer ? `
    background-color ${theme.colours.answer};
  ` : hasMonitorAnswer && `
    background-color ${theme.colours.success};
  `}
  border-radius: 50%;
  display: inline-block;
  margin-left: 1rem;

  ${media.lessThan('phone')`
    margin-left: 0.5rem;
  `
  }
`
const AuthorName = styled.span`
  color: ${({ theme }) => theme.colours.text};
  margin-right: 1rem;
`
export { ThreadItem, ThreadReply }
