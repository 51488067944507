export const featuredTypes = {
  ANSWERED_QUESTIONS: 'Questões respondidas',
  FINISHED_SIMULATEDS: 'Simulados realizados',
  STUDY_PLAN: 'Semanas do seu plano de estudo',
  QUESTION_COURSE: 'Semanas do seu curso por questões',
  WATCHED_CLASSES: 'Aulas assistidas',
}

export const resourcesTypes = {
  CLASS: 'CLASS',
  SUBJECT: 'QUESTIONS_SUBJECT',
  SIMULATED: 'SIMULATED',
  PDFS: 'PDFS',
  VIDEO_GROUP: 'VIDEO_GROUP',
}

export const pdfsTypes = {
  GRAPHIC_SUMMARIES: 'Resumo gráfico',
  QUESTIONS: 'Questões',
}

export const SIMULATED_STATUS = {
  OPEN: 'OPEN',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
}

export const warningMessages = {
  START_SIMULATED: 'Atenção: ao iniciar o simulado, a contagem de tempo será iniciada e só será interrompida após a finalização. Além disso, simulados já iniciados, serão finalizados automaticamente.',
}

export const errorMessages = {
  UNEXPECTED_ERROR: 'Ocorreu um erro inesperado, por favor tente novamente em alguns minutos.',
  NOT_AUTHENTICATED: 'Parece que você não está logado(a) na plataforma.',
  NOT_ALLOWED_TO_FREE: 'Poxa, esse conteúdo está disponível apenas para assinantes.',

  LOGIN_ERROR: 'Não foi possível realizar o login com o e-mail e senha fornecidos. Por favor, verifique os dados e tente novamente.',
  REGISTER_ERROR: 'Não foi possível realizar o seu cadastro com as informações fornecidas. Por favor, verifique os dados e tente novamente.',
  INVALID_RECAPTCHA: 'Recaptcha inválido. Você é um robô?',

  REQUIRED: 'Campo obrigatório',
  MISMATCHED_EMAIL: 'Os e-mails devem ser iguais',
  MISMATCHED_PASSWORD: 'As senhas devem ser iguais',
  MIN_LENGTH_PASSWORD: 'Mínimo de 6 caracteres',
  MIN_LENGTH_NAME: 'Mínimo de 2 caracteres',
  INVALID_CPF: 'CPF inválido',
  INVALID_PHONE: 'Telefone inválido',
  EMAIL_OR_CPF_ALREADY_IN_USE: 'Já existe um cadastro com os dados de e-mail e CPF informados.',

  PROFILE_ERROR: 'Desculpe, não foi possível carregar os dados do seu perfil.',
  FEATURED_STATS_ERROR: 'Desculpe, não foi possível carregar suas estatísticas de uso.',
  DISCIPLINE_STATS_ERROR: 'Desculpe, não foi possível carregar suas estatísticas por disciplina.',
  LATEST_CLASSES_ERROR: 'Desculpe, não foi possível carregar as aulas mais recentes.',
  NOTIFICATIONS_ERROR: 'Desculpe, não foi possível carregar suas notificações mais recentes.',

  DISCIPLINES_ERROR: 'Desculpe, não foi possível carregar a lista de matérias.',

  CLASSES_GROUP_ERROR: 'Desculpe, não foi possível carregar a lista de assuntos de aulas.',
  CLASS_GROUP_ERROR: 'Desculpe, não foi possível carregar a lista de aulas.',

  COMMENTED_TESTS_ERROR: 'Desculpe, não foi possível carregar a lista de provas.',
  COMMENTED_TEST_ERROR: 'Desculpe, não foi possível carregar a prova',

  STUDY_PLANS_ERROR: 'Desculpe, não foi possível carregar os planos de estudo.',
  SINGLE_STUDY_PLAN_ERROR: 'Desculpe, não foi possível carregar os dados do seu plano de estudo.',

  STARTED_STUDY_PLAN_ERROR: 'Desculpe, não foi possível carregar informações sobre o plano de estudo atual.',
  STUDY_PLAN_PRE_START_ERROR: 'Desculpe, não foi possível carregar o cronograma do plano de estudo selecionado.',

  SIMULATED_ERROR: 'Desculpe, não foi possível carregar os simulados.',
  SIMULATED_FILTER_NOT_FOUND: 'Clique no botão abaixo para criar o seu primeiro simulado.',
  QUESTIONS_NOT_FOUND: 'Não foram encontrados questões para o filtro selecionado.',
  SIMULATED_STATS_ERROR: 'Desculpe, não foi possível carregar os dados do simulado.',

  WATCH_CLASS_ERROR: 'Desculpe, não foi possível carregar as informações da sua aula.',

  LIST_PLANS_ERROR: 'Desculpe, não foi possível listar os planos de assinatura.',
  SUPPORT_SINGLE_ERROR: 'Desculpe, não foi possível carregar o chamado.',

  ESSAY_THEME_DETAIL_ERROR: 'Desculpe, não foi possível listar os detalhes do tema.',
  ESSAY_GROUP_ERROR: 'Desculpe, não foi possível carregar a lista de temas.',
}

export const communityTypes = {
  COMMUNITY: 'comunidade',
  COMMUNITY_TAB: 'community',
  NOTIFICATIONS_TAB: 'communityNotifiedTopics',
}
