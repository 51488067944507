import React from 'react'
import t from 'prop-types'
import styled, { keyframes } from 'styled-components'

const ProgressBar = ({ completed, total, background, foreground, showPercentage, showCompletedOfTotal, percentage }) => {
  const percentageValue = completed && total ? getPercentage(completed, total) : percentage

  return (
    <Wrapper>
      {showCompletedOfTotal && <Label>{completed} de {total} aulas</Label>}
      <Bar background={background}>
        <BarCompleted
          style={{ width: `${percentageValue}%` }}
          foreground={foreground}
        />
      </Bar>
      {showPercentage && <Percentage foreground={foreground}>{`${percentageValue}%`}</Percentage>}
    </Wrapper>
  )
}

ProgressBar.propTypes = {
  completed: t.number,
  total: t.number,
  percentage: t.oneOfType([t.number, t.string]),
  background: t.string,
  foreground: t.string,
  showPercentage: t.bool,
  showCompletedOfTotal: t.bool,
}

const getPercentage = (value, max) => {
  let result = (value * 100) / max

  if (value > max) {
    result = 100
  }

  return result.toFixed()
}

const showProgress = keyframes`
  from {
    width: 0;
  }
  to {
    width: ${getPercentage(props => props.completed, props => props.total)}%;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
`

const Bar = styled.div`
  width: 100%;
  border-radius: 2px;
  background: ${({ background }) => background || '#E4E4F3'};
  overflow: hidden;
  height: 4px;
`

const BarCompleted = styled.div`
  background: ${({ foreground, theme }) => theme.darkTheme ? theme.colours.primaryBlue : foreground || theme.colours.primary};
  border-radius: 2px;
  height: 100%;
  width: 0;
  animation: ${showProgress} 1s ease forwards;
`

const Percentage = styled.span`
  font-size: 1.4rem;
  color: ${({ foreground, theme }) => theme.darkTheme ? theme.colours.primaryBlue : foreground || theme.colours.primary};
  font-weight: bold;
  padding-left: 1rem;
`

const Label = styled.p`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
`

export { ProgressBar }
