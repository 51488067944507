import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'
import { pipe, map, prepend } from 'ramda'

export const SUBJECTS_QUERY = gql`
  query GetSubjects($filter:FilterSubjectsInput!){
    subjects (filter: $filter){
     nodes {
       id
       slug
       name
      }
    }
  }
`

export const useSubjectsFetcher = ({ onlyParents, parentSlug, disciplineSlug }) => {
  const { data, loading } = useQuery(SUBJECTS_QUERY, {
    variables: {
      filter: {
        onlyParents,
        parentSlug,
        disciplineSlug,
        showAll: true,
      },
    },
  })
  return {
    subjects: toOptions(data),
    isFetching: loading,
  }
}

const toOption = ({
  slug: value,
  name: label,
}) => ({ label, value })

const toOptions = pipe(
  getNodes('subjects'),
  map(toOption),
  prepend({
    label: 'Todas',
    value: '',
  })
)
