import { gql } from '@apollo/client'

export const DISCIPLINE_STATS_QUERY = gql`
  query GetDisciplineStats {
    stats {
      disciplineStats {
        id
        discipline
        resolved
        total
        percentage
      }
    }
  }
`
