import { useQuery } from '@apollo/client'
import { isEmpty, isNil } from 'ramda'
import { getPagination, getNodes } from 'resources'

const usePaginatedQuery = (QUERY, { nodeName, page = 0, perPage = 5, variables = {}, ...config }) => {
  if (isInvalid(nodeName)) throw TypeError('The "nodeName" must be a string')

  const { data, error, loading, refetch, fetchMore } = useQuery(QUERY, {
    ...config,
    variables: makeVariables({ perPage, page }, variables),
  })

  const setPage = page => {
    refetch({
      ...variables,
      pagination: { page, perPage },
    })
  }

  const setPerPage = perPage => {
    refetch({
      ...variables,
      pagination: { page, perPage },
    })
  }

  return {
    [nodeName]: getNodes(nodeName, data),
    error,
    loading,
    refetch,
    fetchMore,
    pagination: {
      ...getPagination(nodeName, data),
      setPage,
      setPerPage,
    },
  }
}

const isInvalid = value => isEmpty(value) || isNil(value)

const makeVariables = (pagination, variables) => ({
  ...variables,
  pagination,
})

export { usePaginatedQuery }
